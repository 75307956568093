<template>
	<div>
		<Tabs v-model="sel_index" @on-click="index_change">
			<TabPane label="心率" name="heart_rate_columns"></TabPane>
			<TabPane label="体温" name="temperature_columns"></TabPane>
			<TabPane label="血压" v-if="device_m_id !== 7" name="blood_pressure_columns"></TabPane>
			<TabPane label="血氧" name="blood_oxygen_columns"></TabPane>
			<TabPane label="血糖" v-if="device_m_id !== 4 && device_m_id !== 7 && device_m_id !== 10" name="blood_ugar_columns"></TabPane>
			<TabPane label="运动" name="steps_number_columns"></TabPane>
			<TabPane label="睡眠" name="sleep_columns"></TabPane>
		</Tabs>
		<Table :data="data" :columns="columns" border :loading="table_loading"></Table>
		<Page style="text-align: right;margin-top: 10px;" v-model="search.page" :page-size="search.pageSize"
			:total="search.total" :page-size-opts="[10,15,20]" show-sizer show-total @on-change="page_change"
			@on-page-size-change="page_size_change"></Page>
	</div>
</template>

<script>
	export default {
		name: 'HistoricalData',
		data() {
			return {
				table_loading: false, //历史数据-表格加载状态
				sel_index: 'heart_rate_columns', //历史数据-选择查看数据的下标
				//历史数据搜索条件
				search: {
					page: 1, //当前页码
					total: 0, //数据总数
					pageSize: 10 //每页条数
				},
				//历史数据表格显示数据
				data: [],
				//历史数据表格显示表头
				columns: [{
						title: '心率值',
						key: 'amount',
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 心率表格表头
				heart_rate_columns: [{
						title: '心率值',
						key: 'amount',
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 体温表格表头
				temperature_columns: [{
						title: '体温值',
						key: 'amount',
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 血压表格表头
				blood_pressure_columns: [{
						title: '高压-收缩压',
						key: 'high_pressure',
						align: 'center'
					},
					{
						title: '低压-舒张压',
						key: 'low_pressure',
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 血氧表格表头
				blood_oxygen_columns: [{
						title: '血氧值',
						key: 'amount',
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 步数表格表头
				steps_number_columns: [{
						title: '步数',
						key: 'amount',
						align: 'center'
					},
					{
						title: '运动里程',
						render: (h, params) => {
							return h('div', params.row.sportdistance + '千米')
						},
						align: 'center'
					},
					{
						title: '消耗卡路里',
						render: (h, params) => {
							return h('div', params.row.sportcalorie + '千卡')
						},
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 血糖表格表头
				blood_ugar_columns: [{
						title: '血糖值',
						key: 'amount',
						align: 'center'
					},
					{
						title: '状态',
						render: (h, params) => {
							return h('div', this.bgState_arr[params.row.bg_state])
						},
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				// 睡眠表格表头
				sleep_columns: [{
						title: '睡眠总时长',
						render: (h, params) => {
							return h('div', parseFloat(params.row.amount/60).toFixed(2) + '小时')
						},
						align: 'center'
					},
					{
						title: '浅睡时长',
						render: (h, params) => {
							return h('div', parseFloat(params.row.sleeplow/60).toFixed(2) + '小时')
						},
						align: 'center'
					},
					{
						title: '深睡时长',
						render: (h, params) => {
							return h('div', parseFloat(params.row.sleepdeep/60).toFixed(2) + '小时')
						},
						align: 'center'
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
				],
				name_data: ['heart_rate_columns', 'temperature_columns', 'blood_pressure_columns', 'blood_oxygen_columns',
					'blood_ugar_columns', 'steps_number_columns', 'sleep_columns'
				], //历史数据表头
				bgState_arr: ['早空腹', '早饭后半小时', '早饭后一小时', '早饭后两小时', '早饭后三小时', '早饭后三小时以上', '中空腹', '中饭后半小时', '中饭后一小时',
					'中饭后二小时', '中饭后三小时', '中饭后三小时以上', '晚空腹', '晚饭后半小时', '晚饭后一小时', '晚饭后二小时', '晚饭后三小时', '晚饭后三小时以上'
				] // 血糖状态
			}
		},
		props: {
			path: {
				type: String,
				default: '/adm'
			},
			device_imei: {
				type: String,
				default: ''
			},
			device_m_id: {
				type: [Number, String],
				default: 0
			}
		},
		methods: {
			//历史数据-切换tab
			index_change(tab) {
				this.columns = this[tab]
				this.search = {
					page: 1,
					total: 0,
					pageSize: 10
				}
				this.init()
			},
			//获取历史数据
			init() {
				var _this = this;
				let data = this.search;
				data.type = this.name_data.indexOf(this.sel_index);
				data.imei = this.device_imei;
				this.table_loading = true;
				this.requestApi(this.path + '/get_device_data', {
					data
				}).then((res) => {
					_this.table_loading = false;
					if (res.code == 200) {
						_this.data = res.data.data
						_this.search.total = res.data.total
					} else {
						_this.alertErr(res.msg)
					}
				});
			},
			//历史数据切换页码
			page_change(page) {
				this.search.page = page;
				this.init();
			},
			//历史数据切换每页条数
			page_size_change(pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			}
		}
	}
</script>

<style scoped>

</style>
