<template>
	<div class="dtl-body">
		<Menu :active-name="name" width="120px" @on-select="switch_item">
			<MenuItem name="historical_data">历史数据</MenuItem>
			<MenuItem name="historical_footprint">历史足迹</MenuItem>
			<MenuItem name="electronic_fence">电子围栏</MenuItem>
			<MenuItem name="equipment_control">设备控制</MenuItem>
			<MenuItem name="back"><Button style="position: relative; left: -16px; font-size: 12px !important;" @click="$router.go(-1)">返回上一页</Button></MenuItem>
		</Menu>
		<div class="body-right">
			<!-- 历史数据 -->
			<div v-show="name === 'historical_data'">
				<HistoricalData ref="HistoricalData" :path="path" :device_imei="device_imei" :device_m_id="device_m_id"></HistoricalData>
			</div>
			<!-- 历史足迹 -->
			<div v-show="name === 'historical_footprint'">
				<HistoricalFootprint ref="HistoricalFootprint" :path="path" :device_imei="device_imei" :device_m_id="device_m_id"></HistoricalFootprint>
			</div>
			<!-- 电子围栏 -->
			<div v-show="name === 'electronic_fence'">
				<ElectronicFence ref="ElectronicFence" :path="path" :device_imei="device_imei" :device_m_id="device_m_id"></ElectronicFence>
			</div>
			<!-- 设备控制 -->
			<div v-show="name === 'equipment_control'">
				<EquipmentControl ref="EquipmentControl" :path="path" :device_imei="device_imei" :device_m_id="device_m_id"></EquipmentControl>
			</div>
		</div>
	</div>
</template>

<script>
	// var _this;
	// 历史记录-模块
	import HistoricalData from '@/components/device/historical_data';
	// 历史足迹-模块
	import HistoricalFootprint from '@/components/device/historical_footprint';
	// 电子围栏-模块
	import ElectronicFence from '@/components/device/electronic_fence';
	// 设备控制-模块
	import EquipmentControl from '@/components/device/equipment_control';
	export default {
		components: {
			HistoricalData,
			HistoricalFootprint,
			ElectronicFence,
			EquipmentControl
		},
		data() {
			return {
				path: '/adm', 
				name: 'historical_data',
				device_imei: null, //设备imei号
				device_m_id: 1, // 设备厂家id
				data: {}, // 设备详情
				device_type: ['手表', '血压计', '血糖仪', '监控'], // 设备详情 设备类型
				sleep_data: {}, // 健康睡眠
				sleep_date: '' // 健康睡眠 时间
			}
		},
		created() {
			// _this = this
			if (this.$route.query.imei) {
				this.device_imei = this.$route.query.imei;
				this.device_m_id = parseInt(this.$route.query.m_id);
			} else {
				this.alertErr('缺少必要参数');
				setTimeout(() => {
					this.$router.go(-1);
				}, 500)
			}
			var date = new Date();
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			var d = date.getDate();
			this.sleep_date = y + '-' + m + '-' + d
			setTimeout(() => {
				this.init();
			}, 200);
		},
		methods: {
			//根据当前栏目调用获取数据方法
			init() {
				if (this.name == 'details') {
					this.get_device_details();
				} else if (this.name == 'historical_data') {
					this.$refs.HistoricalData.init();
				} else if (this.name == 'historical_footprint') {
					this.$refs.HistoricalFootprint.init();
				} else if (this.name == 'electronic_fence') {
					this.$refs.ElectronicFence.init();
				} else if (this.name == 'equipment_control') {
					this.$refs.EquipmentControl.init();
				} else if (this.name == 'healthy_sleep') {
					this.getsleep();
				}
			},
			// 切换左侧菜单
			switch_item(name) {
				this.name = name;
				this.init();
			}
		}
	}
</script>

<style scoped="scoped">
	/deep/ .ivu-menu-item-selected {
		color: #2D8CF0 !important;
		border-bottom: 0 !important;
	}

	.dtl-body {
		background-color: #fff;
		padding: 16px;
		display: flex;
		padding-bottom: 20px;
	}

	.body-right {
		flex: 1;
		padding: 10px 0px 0px 30px;
	}

	.device_sleep {
		border: 1px solid #DEE2E6;
		width: 100%;
		margin: 0 34px;
	}

	.sleep_head {
		padding: 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.public2 {
		display: flex;
		align-items: baseline;
	}

	.icon-qingjing_shuimian_z {
		margin-right: 20px;
		font-size: 42px;
		color: #04854D;
	}

	.total_sleep {
		color: #3986E8;
		font-size: 40px;
		text-align: right;
	}

	.sleep_bottom {
		border-top: 1px solid #DEE2E6;
		height: 400px;
	}

	.content {
		padding-top: 40px;
		display: flex;
		justify-content: space-evenly;
	}

	.public_sleep {
		display: flex;
		align-items: baseline;
	}

	.sleep_shuimian {
		font-size: 28px;
		margin-right: 8px;
	}

	.public_sleep_z {
		font-size: 16px;
		margin-bottom: 8px;
	}

	.duration {
		border: 1px solid #DEE2E6;
		padding: 10px 28px;
		width: 108px;
		height: 46px;
		font-size: 16px;
		text-align: center;
	}
</style>
